<script setup lang="ts">
const system = useSystemStore();
const { formModalOpen } = storeToRefs(system);

const year = new Date().getFullYear();
const navItems = [
  {
    label: "Resources",
    children: [
      {
        label: "Create a form",
        action: () => (formModalOpen.value = true),
      },
      {
        label: "Pricing",
        href: "/pricing",
      },
      {
        label: "Affiliate Program",
        href: "/affiliate",
      },
      // {
      //   label: "What's New",
      //   href: "/changelog",
      // },
      // {
      //   label: "Roadmap",
      //   href: "/roadmap",
      // },
      {
        label: "Integrations",
        href: "/integrations",
      },
      {
        label: "Privacy Policy",
        href: "/privacy-policy",
      },
      {
        label: "Terms of Service",
        href: "/terms-of-service",
      },
    ],
  },
  {
    label: "Open Source",
    children: [
      {
        label: "FormKit Framework",
        href: "https://developers.formkit.com",
      },
      {
        label: "AutoAnimate",
        href: "https://auto-animate.formkit.com",
      },
      {
        label: "Tempo",
        href: "https://tempo.formkit.com",
      },
      {
        label: "Drag and Drop",
        href: "https://drag-and-drop.formkit.com",
      },
    ],
  },
  {
    label: "About FormKit",
    children: [
      {
        label: "Our Story",
        href: "/our-story",
      },
      {
        label: "Careers",
        href: "/careers",
      },
      {
        label: "@FormKit",
        icon: "pajamas:twitter",
        href: "https://x.com/FormKit",
      },
      {
        label: "@FormKit",
        icon: "mdi:linkedin",
        href: "https://www.linkedin.com/company/formkit",
      },
    ],
  },
];

const handleClick = (item) => {
  if (item.action) {
    item.action();
  }
};
</script>

<template>
  <footer
    class="bg-white-50 border-t border-slate-200 dark:bg-slate-950 dark:border-slate-800"
  >
    <div class="max-w-[1600px] mx-auto px-4 lg:px-8">
      <div class="py-8 px-2 sm:px-10">
        <div class="flex flex-col md:flex-row justify-between">
          <div class="flex flex-col sm:flex-row grow justify-center">
            <ul
              v-for="list in navItems"
              class="mr-20 last:mr-0 mb-10 last:mb-0 sm:mb-0"
            >
              <li
                class="font-header text-[15px] text-slate-700 mb-3 dark:text-white"
              >
                {{ list.label }}
              </li>
              <li
                v-for="item in list.children"
                class="text-[14px] cursor-pointer mb-2 text-slate-400 hover:!text-pink-500 hover:underline text-nowrap dark:text-slate-500"
                @click="handleClick(item)"
              >
                <NuxtLink
                  :to="item.href ? item.href : undefined"
                  :target="
                    item.href && item.href.startsWith('http')
                      ? '_blank'
                      : undefined
                  "
                  class="inline-flex items-center"
                >
                  <Icon
                    v-if="item.icon"
                    :name="item.icon"
                    class="mr-0.5 -mb-0.5"
                    size="1.1em"
                  />
                  {{ item.label }}
                </NuxtLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="pt-4 px-2 sm:px-0 pb-8 border-t border-slate-200 flex flex-col sm:flex-row justify-center dark:border-slate-800"
      >
        <p class="text-xs sm:text-sm text-slate-400/80 order-2 sm:order-1">
          &copy; {{ year }} FormKit, Inc. All rights reserved — Proudly built
          with FormKit Framework
        </p>
      </div>
    </div>
  </footer>
</template>
